body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 
.main-navigation li a {
  color: #fff;
}
.main-navigation .sub-menu li a {
  color: #0009;
} */

.modal-header .btn-close {
  background-color: #fff !important;
  padding: 0.5rem !important;
  color: #000 !important;
  background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e") center/1em auto no-repeat;
}

.modal-header,
.modal-body {
  background-color: var(--assistant-color);
}

.page-image-title {
  position: absolute;
  top: 40vh;
  width: 100%;
  text-align: center;
}

.bg-theme {
  background: #212529 !important;
  color: #fff;
}

.site-header {
  /* background: #c9c9c961; */
}

.site-header.nav-bg img.logo-dark {
  display: none;
}

.site-header.nav-bg img.logo-light {
  display: block;
}

#render {
  overflow: auto;
  text-align: center;
}

#render .node {
  cursor: pointer;
}

#render .node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 1.5px;
}

#render .node text {
  font: 16px "Hiragino Sans GB", "华文细黑", "STHeiti", "微软雅黑", "Microsoft YaHei", SimHei, "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

#render .link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.input-box {
  border: 1px solid gray !important;
  border-radius: 5px;
  height: 50px;
}

.select-dropdown,
.select-dropdown * {
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
}

.select-dropdown {
  position: relative;
  border-radius: 4px;
}

.select-dropdown select {
  font-size: 1rem;
  font-weight: normal;
  padding: 8px 24px 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-dropdown select:active,
.select-dropdown select:focus {
  outline: none;
  box-shadow: none;
}

.select-dropdown:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

.clc-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 10px;
  color: black;
}

.clc {
  letter-spacing: 0px !important;
  border: 1px solid black;
}

/* .countries { */
/* min-height: 500px; */
/* background-image: url(https://static.vecteezy.com/system/resources/previews/003/331/252/original/high-resolution-grey-map-of-the-world-split-into-individual-countries-free-vector.jpg); */
/* background-image: url(https://upload.wikimedia.org/wikipedia/commons/1/17/BlankMap-World-noborders.png); */
/* background-image: url(https://www.pngkey.com/png/full/287-2871296_tried-and-tested-world-map.png); */
/* background-repeat: no-repeat; */
/* background-size: cover; */
/* background-position: center; */
/* } */
.country-bg {
  display: flex;
  flex-wrap: wrap;
}

.c-box {
  display: inline-block;
  margin: 10px;
  flex-grow: 1;
  background: linear-gradient(-45deg, #cf9f5b, #dca960, #a4a4a4, #777978);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100px;
  width: 300px;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.country-title {
  color: white;
  font-size: 22px;
  text-align: center;
  padding: 30px;
  font-weight: 500;
}

.year {
  color: #000;
  font-size: 120px;
  line-height: 90px;
  font-weight: bold;
  margin-right: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.sub-menu {
  min-width: 280px !important;
}

.sr-buttons {
  width: 100%;
  margin: 5px 0px;
  text-align: left;
  padding: 0px !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  cursor: pointer;
}

.sr-buttons.active {
  /* font-size: 16px !important; */
  font-weight: 600;
  color: #c18e4c !important;
}

.accordion-item .accordion-button {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 20px !important;
  display: flex !important;
}

.accordion-item .accordion-body {
  padding: 5px 20px !important;
}

.accordion-button.collapsed::after {
  /* display: inline-block; */
  float: right;
}

.accordion-button:not(.collapsed)::after {
  /* display: inline-block; */
  float: right;
}

.icon-bar {
  position: fixed;
  bottom: 50%;
  z-index: 1;
  background-color: #25D366  ;
  width: 50px !important;
  height: 50px !important;
  right: 2%;
  border-radius: 10px;
}


.icon-bar a {
  display: block;
  text-align: center;
  padding: 5px;
  transition: all 0.3s ease;
  color: 25D366 !important;
  font-size: 20px;
}

.icon-bar a:hover {
  /* background-color: #25D366;
  border-radius: 10px; */
}